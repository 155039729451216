import "../css/fixel.css";

import Alpine from "alpinejs";
import intersect from "@alpinejs/intersect";
import Swiper from "swiper";
import Splitting from "splitting";
import * as rive from "@rive-app/canvas";
import { EffectCards, EffectFade, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

window.Alpine = Alpine;

Alpine.plugin(intersect);

Alpine.store("mobileNav", {
  isOpen: false,

  open() {
    this.isOpen = true;

    document.body.classList.add("overflow-hidden");
  },

  close() {
    this.isOpen = false;

    document.body.classList.remove("overflow-hidden");
  },
});

Alpine.store("customCursor", {
  active: false,
  x: 0,
  y: 0,
  src: null,
  size: 0,

  set(src, size) {
    this.src = src;
    this.size = size;
  },

  setFromStr(str) {
    const [src, size] = str.split("@");
    this.set(src, size);
  },

  setPosition(x, y) {
    this.x = x;
    this.y = y;
  },

  activate() {
    this.active = true;
  },

  deactivate() {
    this.active = false;
  },
});

Alpine.store("modal", {
  active: null,

  init() {},

  isOpen(modal) {
    return this.active === modal;
  },

  open(modal) {
    this.overflowEls.forEach(($el) => $el.classList.add("overflow-hidden"));

    this.active = modal;
  },

  close() {
    if (!this.active) {
      return;
    }

    this.overflowEls.forEach(($el) => $el.classList.remove("overflow-hidden"));

    this.active = null;
  },

  get overflowEls() {
    return document.querySelectorAll("html, body");
  },
});

Alpine.data("spotlight", () => ({
  bounds: null,
  x: 0,
  y: 0,

  init() {
    this.setBounds();
  },

  setBounds() {
    this.bounds = this.$root.getBoundingClientRect();
  },

  setPosition(event) {
    this.x = event.clientX;
    this.y = event.clientY - this.bounds.top;
  },
}));

Alpine.data("testimonialCarousel", () => ({
  swiper: null,

  init() {
    this.swiper = new Swiper(this.$refs.swiper, {
      modules: [Pagination, EffectFade, Autoplay],
      autoplay: {
        delay: 7500,
      },
      loop: true,
      effect: "fade",
      grabCursor: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  },
}));

Alpine.data("imageStack", () => ({
  swiper: null,

  init() {
    this.swiper = new Swiper(this.$refs.swiper, {
      modules: [EffectCards],
      initialSlide: 1,
      grabCursor: true,
      effect: "cards",
      cardsEffect: {
        perSlideRotate: 5,
      },
    });
  },
}));

Alpine.data("loopingImages", (reverse) => ({
  swiper: null,
  reverse,

  init() {
    this.swiper = new Swiper(this.$refs.swiper, {
      modules: [Autoplay],
      loop: true,
      slidesPerView: 1.25,
      speed: 6000,
      spaceBetween: 32,
      allowTouchMove: false,
      autoplay: {
        delay: 1,
        reverseDirection: this.reverse,
        disableOnInteraction: false,
      },
      breakpoints: {
        768: {
          slidesPerView: 2.5,
        },
      },
    });
  },
}));

Alpine.data("work", (data) => ({
  data,
  filters: [],

  get anyFilters() {
    return this.filters.length > 0;
  },

  get filteredData() {
    return this.data.filter((work) => {
      if (!this.anyFilters) {
        return true;
      }

      return this.filters.some((filter) => work.terms.includes(filter));
    });
  },

  isFiltered(filter) {
    return this.filters.includes(filter);
  },

  toggleFilter(filter) {
    if (this.isFiltered(filter)) {
      this.filters = this.filters.filter((f) => f !== filter);
    } else {
      this.filters.push(filter);
    }
  },

  isFull(i) {
    return (i + 1) % 3 === 0;
  },
}));

Alpine.data("rive", (options) => ({
  options,
  rive: null,

  init() {
    this.options.canvas = this.$refs.canvas;
    this.options.autoplay = true;
    this.loop = true;

    this.rive = new rive.Rive(this.options);
  },
}));

Alpine.data("carousel", () => ({
  swiper: null,

  init() {
    this.swiper = new Swiper(this.$refs.carousel, {
      loop: false,
      slidesPerView: 1.25,
      spaceBetween: 26,
      allowTouchMove: true,
      breakpoints: {
        768: {
          slidesPerView: 2.5,
        },
        992: {
          slidesPerView: 3.2,
        },
      },
    });
  },
}));

Alpine.start();

(function () {
  /**
   * Intersect effects
   */

  const intersectEffect = (
    selector,
    initClass,
    threshhold = 0.5,
    delay = 150,
    callback,
  ) => {
    document.querySelectorAll(selector).forEach((el) => {
      let observer = new IntersectionObserver(
        (entries) => {
          if (el.classList.contains(initClass)) {
            observer.disconnect();
            return;
          }

          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setTimeout(() => {
                el.classList.add(initClass);
              }, delay);
            }
          });
        },
        { threshhold },
      );

      observer.observe(el);
    });

    if (callback) {
      callback(selector, initClass, threshhold, delay);
    }
  };

  // Stagger
  intersectEffect(
    ".stagger",
    "stagger-init",
    1.0,
    150,
    (selector, initClass, threshhold, delay) => {
      document.querySelectorAll(selector).forEach((el) => {
        Splitting({ target: el, by: "words" });

        el.querySelectorAll(".word").forEach((word, i) => {
          word.replaceChildren(
            document
              .createRange()
              .createContextualFragment(
                `<span class="stagger-wrap"><span class="stagger-slide" style="--delay: ${i * 0.1}s">${word.textContent}</span></span>`,
              ),
          );
        });
      });
    },
  );

  // Fade
  intersectEffect(".fade", "fade-init", 0.25);

  /**
   * Button hovers
   */

  document.querySelectorAll(".button, .wp-block-button__link").forEach((el) => {
    el.innerHTML = `
      <span class="button-bg"></span>
      <span class="button-inner">
        <span class="button-text">${el.innerHTML}</span>
        <span class="button-text-hover">${el.innerHTML}</span>
      </span>
    `;
  });

  /**
   * Custom cursors
   */
  document.querySelectorAll("[data-custom-cursor]").forEach((el) => {
    el.addEventListener("mouseenter", () => {
      Alpine.store("customCursor").activate();
      Alpine.store("customCursor").setFromStr(el.dataset.customCursor);
    });

    el.addEventListener("mouseleave", () => {
      Alpine.store("customCursor").deactivate();
    });
  });

  // AlpineJS Modal
  document.querySelectorAll(`[href^="#modal-"]`).forEach(($el) => {
    $el.addEventListener("click", (ev) => {
      ev.preventDefault();

      Alpine.store("modal").open(
        $el.getAttribute("href").replace("#modal-", ""),
      );
    });
  });

  const updateCursor = () => {
    const hoveredEl = document.elementFromPoint(
      Alpine.store("customCursor").x,
      Alpine.store("customCursor").y,
    );

    const cursorEl = hoveredEl.closest("[data-custom-cursor]") || hoveredEl;

    if (!cursorEl.hasAttribute("data-custom-cursor")) {
      return;
    }

    Alpine.store("customCursor").setFromStr(cursorEl.dataset.customCursor);
  };

  document.addEventListener("mousemove", (ev) => {
    Alpine.store("customCursor").setPosition(ev.clientX, ev.clientY);

    requestAnimationFrame(updateCursor);
  });
})();
